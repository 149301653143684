/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';

import type { AppContent, FeatureServiceDependencies } from '../@types/interfaces';

import FeatureApp from './FeatureApp';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'styled-components': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const contentService = featureServices['audi-content-service'];

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    // on the server
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          const content = (await contentService?.getContent()) as AppContent;
          serializedStateManager?.register(() => JSON.stringify(content));
        })(),
      );
    }
    return {
      render: () => (
        <FeatureApp
          configProvider={featureServices['gfa:service-config-provider']}
          contentService={contentService}
          trackingService={featureServices['audi-tracking-service']}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          footnoteReferenceService={featureServices['audi-footnote-reference-service']}
        />
      ),
    };
  },
};

export default featureAppDefinition;
