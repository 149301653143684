import styled from 'styled-components';

import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  SPACING_XL_XS,
  SPACING_XXXL_M,
  SPACING_XXXL_L,
  SPACING_XXL_L,
  SPACING_XXXL_XL,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
} from '@audi/audi-ui-design-tokens';
import type { TextWrapperProps } from '../../@types/interfaces';

export const ContainerDiv = styled.div`
  background-color: ${(props) =>
    props.theme === 'light' ? COLOR_BASE_BRAND_WHITE : COLOR_BASE_BRAND_BLACK};
  padding: ${SPACING_XXL_L}px ${SPACING_XL_XS}px;

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: ${SPACING_XXXL_M}px ${SPACING_XXL_L}px;
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    padding: ${SPACING_XXXL_L}px;
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    padding: ${SPACING_XXXL_XL}px 96px;
  }
`;

export const ButtonContainerDiv = styled.div`
  margin-block-start: ${SPACING_XXL_L}px;
  margin-bottom: 0;
`;

export const TextWrapperDiv = styled.div<TextWrapperProps>`
  text-align: ${(props) => props.align};

  pre {
    white-space: pre-wrap;
  }
`;
