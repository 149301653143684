import React from 'react';
import {
  audiDarkTheme,
  audiLightTheme,
  Layout,
  LayoutItem,
  Button,
  ThemeProvider,
  GlobalStyles,
} from '@audi/audi-ui-react';

import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';

import type { EditorialTextProps } from '../../@types/types';

import { processBodyText } from './processBodyText';

import { ContainerDiv, ButtonContainerDiv, TextWrapperDiv } from './styledComponents';

export const EditorialText = ({ content, footnoteReferenceService }: EditorialTextProps) => {
  const { bodyText, theme, justification, ctaButtonLabel, ctaButtonUrl, ctaButtonOpenInNewTab } =
    content;

  const themeActive = theme === 'dark' ? audiDarkTheme : audiLightTheme;

  let testingThemeActive = 'unknown';
  if (themeActive.name.toLowerCase() === 'audi dark theme') {
    testingThemeActive = 'dark';
  }
  if (themeActive.name.toLowerCase() === 'audi light theme') {
    testingThemeActive = 'light';
  }
  const processedBodyText = processBodyText(bodyText);

  return (
    <div data-theme-name={testingThemeActive}>
      <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService || {}}>
        <GlobalStyles />
        <ThemeProvider theme={themeActive}>
          {bodyText && (
            <ContainerDiv theme={theme}>
              <Layout data-test-id="title" direction="column">
                <LayoutItem align="auto" basis="100%">
                  <TextWrapperDiv data-testid="textWrapper" align={justification} theme={theme}>
                    {processedBodyText}
                  </TextWrapperDiv>
                </LayoutItem>

                {ctaButtonUrl?.length > 0 && ctaButtonLabel?.length > 0 && (
                  <LayoutItem align={justification} basis="auto">
                    <ButtonContainerDiv>
                      <Button
                        variant="text"
                        aria-label={ctaButtonLabel}
                        newWindow={ctaButtonOpenInNewTab}
                        href={ctaButtonUrl}
                        onClick={() => {}}
                      >
                        {ctaButtonLabel}
                      </Button>
                    </ButtonContainerDiv>
                  </LayoutItem>
                )}
              </Layout>
            </ContainerDiv>
          )}
        </ThemeProvider>
      </FootnoteContextProvider>
    </div>
  );
};
