import type { AppContent, FalconContent } from '../../@types/interfaces';

export const normalizeContent = (content: AppContent | FalconContent): AppContent => {
  // If content is a mock or old NeMo just use it directly
  if (content?.__type !== 'aem-headless') {
    return content;
  }

  return normalizeAemHeadlessContent(content);
};

const normalizeAemHeadlessContent = (content: FalconContent): AppContent => {
  const { fields } = content;
  const { theme, bodyText, justification, ctaButtonLabel, ctaButtonUrl, ctaButtonOpenInNewTab } =
    fields;

  const contentForCMS: AppContent = {
    theme,
    bodyText,
    justification,
    ctaButtonLabel,
    ctaButtonUrl,
    ctaButtonOpenInNewTab,
  };

  const formattedContent: AppContent = {
    ...contentForCMS,
  };

  return formattedContent;
};
