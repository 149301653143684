import sanitizeHtml from 'sanitize-html';
import { convertShortcodeHyphen } from './convertShortcodeHyphen';
import { convertShortcodeDivider } from './convertShortcodeDivider';
import { convertShortcodeBlockQuote } from './convertShortcodeBlockQuote';
import { allowedTagsAndAttributes } from './allowedTagsAndAttributes';
import { convertHtmlToReact } from './convertHtmlToReact';

export const processBodyText = (bodyText: string) => {
  const sanitizedBodyText = sanitizeHtml(bodyText, allowedTagsAndAttributes);
  const withHyphens = convertShortcodeHyphen(sanitizedBodyText);
  const withDivider = convertShortcodeDivider(withHyphens);
  const withBlockQuote = convertShortcodeBlockQuote(withDivider);
  const processedBodyText = convertHtmlToReact(withBlockQuote);
  return processedBodyText;
};
