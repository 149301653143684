import React, { useEffect, useState } from 'react';
import { App as OneAudiApp } from '@oneaudi/oneaudi-os-react';
import { ContentChangeListener } from '@volkswagen-onehub/audi-content-service';
import { createTrackingService } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';

import type { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import type { AppContent, FeatureAppProps } from '../@types/interfaces';

import { EditorialText } from './components/EditorialText';
import { normalizeContent } from './components/normalizeContent';

import mockContent from './content.json';

const FeatureApp: React.FC<FeatureAppProps> = ({ ...inherited }: FeatureAppProps) => {
  const {
    contentService,
    trackingService: trackingServiceInherited,
    footnoteReferenceService,
  } = inherited;

  let trackingService = {} as TrackingServiceV2;

  if (typeof window !== 'undefined') {
    trackingService = trackingServiceInherited || createTrackingService(__FEATURE_APP_NAME__);
  }

  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  const [rawContent, setRawContent] = useState(contentService?.getContent() as AppContent);
  contentService?.onContentChange(setRawContent as ContentChangeListener);

  useEffect(() => {
    trackingService.featureAppName = __FEATURE_APP_NAME__;
    const readyEvent = {
      event: {
        attributes: {
          componentName: __FEATURE_APP_NAME__,
          elementName: '',
          label: '',
          clickID: '',
          pos: '',
          targetURL: '',
          value: '',
        },
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${__FEATURE_APP_NAME__} - Feature App ready`,
        },
      },
    };
    trackingService.track(readyEvent);
  }, []);

  const isSSR = typeof window === 'undefined';
  const [onFirstRender, setOnFirstRender] = useState(true);
  useEffect(() => {
    setOnFirstRender(false);
  }, []);

  const content = normalizeContent(rawContent ?? mockContent);

  return (
    <OneAudiApp config={appConfig} app={app} {...inherited}>
      {isSSR || onFirstRender ? null : (
        <EditorialText
          content={content}
          footnoteReferenceService={footnoteReferenceService?.getDefaultScopeRefService()}
        />
      )}
    </OneAudiApp>
  );
};

export default FeatureApp;
